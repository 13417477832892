<template>
  <div
    ref="sectionRef"
    :class="
      data.background === BACKGROUNDS.BLACK
        ? 'bg-neutral-800 text-white'
        : 'bg-white text-neutral-800'
    "
  >
    <div class="container py-28 lg:py-36">
      <div v-if="data.title || data.image" class="grid-default mb-14 gap-y-10 lg:mb-20">
        <Heading
          v-if="data.title"
          :content="data.title"
          :level="2"
          class="col-span-12 lg:col-span-7 !text-2xl lg:!text-6xl"
        />
        <div v-if="data.image" class="col-span-12 lg:col-start-9 lg:col-end-13">
          <Image
            loading="lazy"
            class="aspect-5/4 rounded-[40px] object-cover"
            sizes="(min-width: 1920px) 597px, (min-width: 1400px) 405px, (min-width: 1040px) calc(26.47vw + 40px), calc(100vw - 32px)"
            :src="data.image.responsiveImage ? data.image.responsiveImage?.src : data.image.url"
            :srcset="
              data.image.responsiveImage ? data.image.responsiveImage?.webpSrcSet : undefined
            "
            :width="
              data.image.responsiveImage ? data.image.responsiveImage.width : data.image.width
            "
            :height="
              data.image.responsiveImage ? data.image.responsiveImage.height : data.image.height
            "
            :alt="data.image.alt ? data.image.alt : ''"
          />
        </div>
      </div>
      <LazyFeaturesList v-if="isSectionVisible" :data="data.features" />
    </div>
  </div>
</template>

<script setup lang="ts">
enum BACKGROUNDS {
  WHITE = 'White',
  BLACK = 'Black'
}

defineProps({
  data: {
    type: Object,
    default: () => {}
  }
})

const sectionRef = ref<HTMLElement | null>(null)
const isSectionVisible = ref(false)

const { stop } = useIntersectionObserver(sectionRef, ([{ isIntersecting }], _observerElement) => {
  if (isIntersecting) {
    isSectionVisible.value = true
  }
})

onUnmounted(() => {
  stop()
})
</script>
